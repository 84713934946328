import { Link } from '@inertiajs/react';
import { Icons } from '@/Components/Icons';
import { NavbarBrand } from '@nextui-org/navbar';

const NavbarBranding = () => (
  <NavbarBrand>
    <Link
      href={route('home')}
      className="flex justify-center items-center z-40"
    >
      <Icons.logo className="md:size-10 mr-2"/>
      <span className="self-center md:text-2xl font-semibold whitespace-nowrap">
          reminara
        </span>
    </Link>
  </NavbarBrand>
);

export default NavbarBranding;