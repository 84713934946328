import Navbar from '@/Components/Navbar/Navbar.tsx'
import Footer from '@/Components/Footer.tsx'
import { Toaster } from '@/Components/ui/sonner'
import { usePage } from '@inertiajs/react'

const RootLayout = ({ children }) => {

  return (
    <div className="grainy">
      <Navbar auth={usePage().props.auth ?? {}}/>
      <main>
        {children}
      </main>

      <Toaster richColors theme="light"/>
      <Footer/>
    </div>
  )
}

export default RootLayout
