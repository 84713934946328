import {Link} from '@inertiajs/react';
import {ButtonProps, buttonVariants} from '@/Components/ui/button';
import {ArrowRight} from 'lucide-react';
import {t} from 'i18next';
import React from "react";

interface GettingStartedProps {
    size: ButtonProps['size'];
    className?: string;
}

const GettingStarted: React.FC<GettingStartedProps> = ({ size, className }) => {
    return (
        <Link
            className={buttonVariants({size, className})}
            href={route('register')}
        >
            {t('buttons.startNow')}
            <ArrowRight className="h-5 w-5" />
        </Link>
    );
};

export default GettingStarted;
