import {Link} from '@inertiajs/react';
import {buttonVariants} from '@/Components/ui/button';
import {useTranslation} from 'react-i18next';
import GettingStarted from '@/Components/Buttons/GettingStarted';
import {NavbarItem} from "@nextui-org/navbar";

const AuthButtons = () => {
  const { t } = useTranslation();

  return (
    <>
      <NavbarItem>
        <Link href={route('login')} className={buttonVariants({ variant: 'ghost', size: 'sm' })}>
          {t('buttons.login')}
        </Link>
      </NavbarItem>
      <NavbarItem>
        <GettingStarted size="sm"/>
      </NavbarItem>
    </>
  );
};

export default AuthButtons;
