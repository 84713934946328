import { Edit, Gem } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/Components/ui/dropdown-menu';
import { Icons } from '@/Components/Icons';
import { Button } from '@/Components/ui/button';
import { Avatar, AvatarFallback } from '@/Components/ui/avatar';
import { Link } from '@inertiajs/react';
import { useTranslation } from 'react-i18next';

const UserAccountNav = ({ email, name }: {email: string, name: string}) => {
  const { t } = useTranslation();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild className="overflow-visible">
        <Button className="rounded-full h-8 w-8 aspect-square bg-slate-400">
          <Avatar className="relative w-8 h-8">
            <AvatarFallback>
              <span className="sr-only">{name}</span>
              <Icons.user className="h-4 w-4 text-zinc-900"/>
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="bg-white" align="end">
        <div className="flex items-center justify-start gap-2 p-2">
          <div className="flex flex-col space-y-0.5 leading-none">
            {name && (
              <p className="font-medium text-sm text-black">
                {name}
              </p>
            )}
            {email && (
              <p className="w-[200px] truncate text-xs text-zinc-700">
                {email}
              </p>
            )}
          </div>
        </div>

        <DropdownMenuSeparator/>

        <DropdownMenuItem asChild className="cursor-pointer">
          <Link href={route('profile.edit')}>
            {t('navbar.user.profile.title')}
            <Edit className="h-4 w-4 ml-2 text-blue-500"/>
          </Link>
        </DropdownMenuItem>

        <DropdownMenuSeparator/>

        <DropdownMenuItem asChild className="cursor-pointer">
          <Link href={route('dashboard')}>
            {t('buttons.dashboard')}
          </Link>
        </DropdownMenuItem>

        <DropdownMenuItem asChild className="pointer-events-none">
          <Link href={`${route('home')}#pricing`} className="opacity-50">
            {t('buttons.upgradeNow') + ' '}
            <Gem className="text-blue-500 h-4 w-4 ml-1.5"/>
          </Link>
        </DropdownMenuItem>

        <DropdownMenuSeparator/>

        <DropdownMenuItem asChild className="cursor-pointer w-full">
          <Link href={route('logout')} method="post" as="button">
            {t('buttons.logout')}
          </Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UserAccountNav;
