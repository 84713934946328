import {Navbar as NexUINavbar, NavbarContent, NavbarMenu, NavbarMenuToggle} from '@nextui-org/navbar';
import {useState} from 'react';
import MobileNavbar from '@/Components/Navbar/MobileNavbar';
import NavbarBranding from '@/Components/Navbar/NavbarBranding';
import GuestLinks from '@/Components/Navbar/GuestLinks';
import AuthButtons from '@/Components/Navbar/AuthButtons';
import UserLinks from '@/Components/Navbar/UserLinks';
import {PageProps} from "@/types";

const Navbar = ({auth}: PageProps) => {
    const user = auth.user;

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <NexUINavbar onMenuOpenChange={setIsMenuOpen} isMenuOpen={isMenuOpen} maxWidth="xl">
            <NavbarContent>
                <NavbarBranding/>
            </NavbarContent>

            <NavbarContent className="hidden lg:flex gap-4" justify="center">
                {!user && <GuestLinks/>}
            </NavbarContent>

            <NavbarContent className="hidden lg:flex" justify="end">
                {!user ? (
                    <AuthButtons/>
                ) : (
                    <UserLinks auth={auth}/>
                )}
            </NavbarContent>

            <NavbarContent className="lg:hidden basis-1 pl-4" justify="end">
                <NavbarMenuToggle aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}/>
            </NavbarContent>

            <NavbarMenu>
                <MobileNavbar isAuth={!!user}/>
            </NavbarMenu>
        </NexUINavbar>
    );
};

export default Navbar;
