import {Link} from '@inertiajs/react';
import {buttonVariants} from '@/Components/ui/button';
import {useTranslation} from 'react-i18next';
import {NavbarItem} from '@nextui-org/navbar';
import {isAnchorActive} from '@/lib/utils';

const GuestLinks = () => {
  const { t } = useTranslation();

  return (
    <>
      <NavbarItem>
        <Link
          href={`${route('home')}#features`}
          className={buttonVariants({
            variant: 'ghost',
            size: 'sm',
            className: isAnchorActive('features') && 'bg-gray-200'
          })}
        >
          {t('navbar.features')}
        </Link>
      </NavbarItem>
      <NavbarItem>
        <Link
          href={`${route('home')}#pricing`}
          className={buttonVariants({
            variant: 'ghost',
            size: 'sm',
            className: isAnchorActive('pricing') && 'bg-gray-200'
          })}
        >
          {t('navbar.pricing')}
        </Link>
      </NavbarItem>
      <NavbarItem>
        <Link
          href={`${route('home')}#faq`}
          className={buttonVariants({
            variant: 'ghost',
            size: 'sm',
            className: isAnchorActive('faq') && 'bg-gray-200'
          })}
        >
          {t('navbar.faq')}
        </Link>
      </NavbarItem>
    </>
  );
};

export default GuestLinks;
