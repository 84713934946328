import { Edit, Gem } from 'lucide-react';
import { Link, usePage } from '@inertiajs/react';
import { useTranslation } from 'react-i18next';
import { NavbarMenuItem } from '@nextui-org/navbar';
import { buttonVariants } from '@/Components/ui/button';
import GettingStarted from '@/Components/Buttons/GettingStarted';
import { isAnchorActive } from '@/lib/utils';

const MobileNavbar = ({ isAuth }: {isAuth: boolean}) => {
  const { t } = useTranslation();
  const { url } = usePage();

  return (
    <div className="lg:hidden w-full gap-2">
      {!isAuth ? (
        <div className="mx-4 mt-2 flex flex-col gap-2">
          <NavbarMenuItem isActive={isAnchorActive('features')}>
            <Link href={`${route('home')}#features`}>
              {t('navbar.features')}
            </Link>
          </NavbarMenuItem>
          <NavbarMenuItem isActive={isAnchorActive('pricing')}>
            <Link href={`${route('home')}#pricing`}>
              {t('navbar.pricing')}
            </Link>
          </NavbarMenuItem>
          <NavbarMenuItem isActive={isAnchorActive('faq')}>
            <Link href={`${route('home')}#faq`}>
              {t('navbar.faq')}
            </Link>
          </NavbarMenuItem>

          <NavbarMenuItem className="my-3 h-px bg-gray-300"/>

          <NavbarMenuItem>
            <Link href={route('login')} className={buttonVariants({
              size: 'sm',
              variant: 'outline',
              className: 'w-full'
            })}>
              {t('buttons.login')}
            </Link>
          </NavbarMenuItem>
          <NavbarMenuItem>
            <GettingStarted size="sm" className="w-full"/>
          </NavbarMenuItem>
        </div>
      ) : (
        <>
          <NavbarMenuItem isActive={url === '/profile'}>
            <Link href={route('profile.edit')} className="flex items-center">
              {t('navbar.user.profile.title')}
              <Edit className="h-4 w-4 ml-2 text-blue-500"/>
            </Link>
          </NavbarMenuItem>

          <NavbarMenuItem className="my-3 h-px bg-gray-300"/>

          <NavbarMenuItem isActive={url === '/dashboard'}>
            <Link href={route('dashboard')} className="flex mb-1">
              {t('dashboard.title')}
            </Link>
          </NavbarMenuItem>

          <NavbarMenuItem isActive={isAnchorActive('pricing')}>
            <Link
              href={`${route('home')}#pricing`}
              className="flex items-center w-full font-semibold opacity-50 pointer-events-none cursor-none"
            >
              {t('buttons.upgradeNow')}
              <Gem className="text-blue-500 h-4 w-4 ml-2"/>
            </Link>
          </NavbarMenuItem>

          <NavbarMenuItem className="my-3 h-px bg-gray-300"/>

          <NavbarMenuItem>
            <Link
              href={route('logout')}
              method="post"
              as="button"
              className={buttonVariants({ variant: 'destructive', size: 'sm', className: 'w-full' })}
            >
              {t('buttons.logout')}
            </Link>
          </NavbarMenuItem>
        </>
      )}
    </div>
  );
};

export default MobileNavbar;
