import { Link } from '@inertiajs/react';
import { buttonVariants } from '@/Components/ui/button';
import { useTranslation } from 'react-i18next';
import { NavbarItem } from '@nextui-org/navbar';
import UserAccountNav from '@/Components/Navbar/UserAccountNav';
import {PageProps} from "@/types";

const UserLinks = ({ auth }: PageProps) => {
  const { t } = useTranslation();
  const user = auth.user;

  return (
    <>
      <NavbarItem>
        <Link href={route('dashboard')} className={buttonVariants({ variant: 'ghost', size: 'sm' })}>
          {t('buttons.dashboard')}
        </Link>
      </NavbarItem>
      <NavbarItem>
        <UserAccountNav
          name={`${user!.firstname} ${user!.lastname}`}
          email={user!.email}
        />
      </NavbarItem>
    </>
  );
};

export default UserLinks;
